<template>
  <div class="mb-0">
    <div
      v-if="showSuccessModal"
      class="modal"
      tabindex="-1"
      role="dialog"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
      "
    >
      <div
        style="
          background-color: var(--white);
          color: black;
          max-width: 768px;
          width: 100%;
          height: 80vh;
        "
        class="modal-content"
      >
        <div class="modal-body">
          <div style="border-bottom: solid black 2px">
            <div class="row m-0 p-0">
              <div class="col-8 p-0 m-0">
                <div class="d-flex">
                  <span
                    style="background-color: var(--darkBackground)"
                    class="btn btn-sm slip-text-header text-light close-text"
                    id="faso-close"
                    @click="showSuccessModal = false"
                  >
                    [x]
                  </span>
                </div>
              </div>
              <div class="col-4 p-0 m-0">
                <div style="float: right" class="d-flex">
                  <span class="slip-text-header"> </span>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              style="width: 150px; margin-top: 40px"
              src="/img/check-icons.png"
              alt="Check"
            />
          </div>
          <br />
          <h5 style="text-align: center; color: var(--darkBackground)">
            The bet has been successfully placed.
          </h5>
          <br />
          <br />
          <div
            class="text-center d-flex justify-content-center align-items-center"
            style="gap: 10px"
          >
            <a
              @click="shareMyBet = true"
              style="
                background-color: var(--darkBackground);
                border-radius: 5px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="join-button py-2 form-control"
            >
              <span style="color: var(--white)">Share bet</span>
            </a>
          </div>
          <br />
          <br />
          <div
            v-if="shareMyBet"
            style="
              background-color: var(--darkBackground);
              height: 20%;
              border-radius: 8px;
            "
          >
            <br />
            <div class="text-yellow text-center matches-title mb-2">
              Share Betslip
            </div>

            <div
              class="social-icons d-flex justify-content-around px-2 text-center mb-2"
            >
              <a
                href="https://www.facebook.com/chopLifeKE/"
                target="_blank"
                title="Tweet"
                onclick="window.open('https://www.facebook.com/chopLifeKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
              >
                <img
                  style="width: 25px; height: 25px"
                  src="/img/facebook.png"
                /><br />
                <span class="text-blue small-text"> Facebook</span>
              </a>

              <a
                href="https://instagram.com/chopLife?utm_medium=copy_link&t="
                title="Share on Facebook"
                onclick="window.open('https://instagram.com/chopLife?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                target="_blank"
              >
                <img
                  style="width: 25px; height: 25px"
                  src="/img/instagram.png"
                />
                <br />
                <span class="text-blue small-text"> Instagram</span>
              </a>

              <a
                data-action="share/whatsapp/share"
                target="_blank"
                onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;"
              >
                <img
                  style="width: 25px; height: 25px"
                  src="/img/whatsapp.png"
                /><br />
                <span class="text-blue small-text"> Whatsapp</span>
              </a>

              <a
                href="https://twitter.com/chopLifeKE?s=11"
                target="_blank"
                title="Tweet"
                onclick="window.open('https://twitter.com/chopLifeKE?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
              >
                <img
                  style="width: 25px; height: 25px"
                  src="/img/twitter.png"
                /><br />
                <span class="text-blue small-text"> Twitter</span>
              </a>

              <a
                data-action="share/telegram/share"
                target="_blank"
                onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
              >
                <img
                  style="width: 25px; height: 25px"
                  src="/img/telegram.png"
                /><br />
                <span class="text-blue small-text"> Telegram</span>
              </a>
            </div>

            <div class="row p-0 m-0 px-2 mb-2">
              <div class="col-8 px-1">
                <div
                  id="share-bet-link"
                  class="share-betslip-btn"
                  style="color: #ffffff"
                >
                  https://chopLife.com/share/{{ bet_id }}
                </div>
                <div class="d-none" id="share-bet-code">{{ code }}</div>
                <div class="d-none" id="share-bet-odds">{{ odds }}</div>
                <div class="d-none" id="share-bet-msg">{{ msg }}</div>
              </div>
              <div class="col-4 px-1">
                <button class="share-btn" @click="copyShareBetLink">
                  {{ copyText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomNavigationMenu
      v-bind="{
        isLoggedIn: isLoggedIn,
        normalBetsCount: normalBetsCount,
        betslip_count: betslip_count,
        liveGames: liveGames,
      }"
    >
      <BetslipModalToggle
        v-bind="{
          betslip_count: betslip_count,
          odds: odds,
          payout: payout,
        }"
      />
    </BottomNavigationMenu>
    <span id="open-sharebet" class="hidden"></span>

    <span id="betslip-init" class="hidden"></span>

    <div
      class="modal betslip-modal fade"
      id="showBetSlip"
      tabindex="-1"
      aria-labelledby="showBetSlipLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div
          style="background-color: white; color: black"
          class="modal-content"
        >
          <div class="modal-header">
            <div class="betslip-modal-header">
              <span
                class="slip-counter-header slip-text-header"
                style="font-weight: bold"
              >
                {{ betslip_count }}
              </span>
              <h5 class="modal-title" id="showBetSlip">Betslip</h5>
              <span
                v-if="betslip_count !== 0"
                class="btn btn-sm slip-text-header text-white"
                @click="clearAndCloseBetSlip"
              >
                Remove All
              </span>
            </div>
            <span
              type="button"
              class="close"
              id="faso-close"
              data-dismiss="modal"
              style="display: none"
              >&times;</span
            >
          </div>

          <div class="modal-body">
            <div>
              <div
                class="collapse details-collapse py-2 px-4"
                id="collapseBetmenu"
                v-show="betslip_count > 0"
              >
                <div class="text-yellow">
                  <strong>Settings</strong>
                </div>
                <div class="text-yellow">Enable odd change automatically</div>
                <div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="checkbox"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      class="custom-control-input"
                      v-model="accept_odds_changes"
                    />
                    <label
                      class="custom-control-label text-light"
                      for="customRadioInline1"
                      >Accept Odds Changes</label
                    >
                  </div>
                </div>
              </div>

              <div class="faso-modal-body slip-list-items">
                <Pick
                  v-show="betslip_count > 0"
                  v-for="(bet, index) in betslip.picks"
                  v-bind:key="index"
                  v-bind:bet="bet"
                >
                </Pick>
              </div>

              <div class="" style="width: 100%; /*height: 10em*/">
                <div
                  v-if="betslip_count !== 0"
                  class="faso-modal-footer d-flex flex-column pb-0"
                  style="padding: 2px 16px"
                >
                  <section
                    class="dets px-0 py-2"
                    style="background-color: white; color: black"
                  >
                    <div class="mb-1" v-if="isLoggedIn && profile.b2 > 0">
                      <div class="frebet-title text-dark bg-light px-1">
                        Your Bonus
                      </div>
                      <div class="d-flex justify-content-between bg-light p-1">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            :disabled="betslip.odds < 3.5"
                            :checked="can_get_bonus"
                            v-on:click="bonusUpdate"
                          />
                          <label
                            class="form-check-label text-dark"
                            for="flexRadioDefault2"
                          >
                            Use {{ profile.b2 | currency }} Bonus
                          </label>
                        </div>
                        <div
                          v-if="betslip.odds > 3.4"
                          class="text-dark font-weight-bold font-size-11"
                        >
                          Available
                        </div>
                        <div
                          v-if="betslip.odds < 3.5"
                          class="font-size-11"
                          style="color: #707070"
                        >
                          Unavailable
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="slip-amount-bg">
                        <div class="mb-0">
                          <div
                            class="input-group"
                            style="
                              border: 1px black solid;
                              border-radius: 5px;
                              background-color: white;
                              color: black;
                              width: 100%;
                              height: 40px;
                              text-align: center;
                            "
                          >
                            <div
                              class="input-group-prepend"
                              @click="decrementStake"
                              style="
                                border-radius: 5px;
                                background-color: white;
                                color: black;
                                text-align: center;
                              "
                            >
                              <span
                                style="background-color: var(--darkBackground)"
                                class="btn btn-sm"
                                type="button"
                                ><img
                                  style="width: 20px; margin-top: 5px"
                                  :src="btnMinus"
                              /></span>
                            </div>
                            <input
                              type="number"
                              class="form-control"
                              id="stake"
                              @keyup="checkLetters"
                              style="
                                border-radius: 5px;
                                background-color: white;
                                color: black;
                                text-align: center;
                              "
                              name="password"
                              placeholder="100"
                              aria-label="Amount"
                              v-model="stake"
                              :disabled="can_get_bonus"
                            />
                            <div
                              class="input-group-prepend"
                              @click="incrementStake"
                              style="
                                border-radius: 5px;
                                background-color: white;
                                color: black;
                                text-align: center;
                              "
                            >
                              <span
                                style="background-color: var(--darkBackground)"
                                class="btn btn-sm"
                                type="button"
                                ><img
                                  style="width: 20px; margin-top: 5px"
                                  :src="btnPlus"
                              /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="slip-amount-bg">
                        <div
                          class="prealloc-amts d-flex justify-content-between"
                        >
                          <div class="depo-btn-wrapper">
                            <button
                              class="btn odd-btn px-2"
                              @click="setAmount(20)"
                            >
                              +<span class="deposit_val">20</span>
                            </button>
                          </div>
                          <div class="depo-btn-wrapper">
                            <button
                              class="btn odd-btn px-2"
                              @click="setAmount(50)"
                            >
                              +<span class="deposit_val">50</span>
                            </button>
                          </div>
                          <div class="depo-btn-wrapper">
                            <button
                              class="btn odd-btn px-2"
                              @click="setAmount(100)"
                            >
                              +<span class="deposit_val">100</span>
                            </button>
                          </div>
                          <div class="depo-btn-wrapper">
                            <button
                              class="btn odd-btn px-2"
                              @click="setAmount(500)"
                            >
                              +<span class="deposit_val">500</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <BetslipSummary :betslip="betslip" />

                    <div
                      style="margin-bottom: 5px; border-radius: 5px"
                      class="collapse details-collapse px-2"
                      id="collapseDetails"
                    >
                      <div class="d-none justify-content-between">
                        <div class="dets-sub small-text text-blue">
                          Excise tax
                        </div>
                        <div class="dets-val small-text text-blue">
                          -{{ fiatCurrency }} 8.00
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="dets-sub small-text text-blue">
                          Stake tax
                        </div>
                        <div class="dets-val small-text text-blue">
                          {{ (stake - (stake * 100) / (100 + 7.5)) | currency }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="dets-sub small-text text-blue">
                          Stake After tax
                        </div>
                        <div class="dets-val small-text text-blue">
                          {{ ((stake * 100) / (100 + 7.5)) | currency }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="dets-sub small-text text-blue">WH tax</div>
                        <div class="dets-val small-text text-blue">
                          {{ betslip.tax | currency }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="py-2 text-danger text-center text-underline d-none"
                    >
                      <u
                        v-if="has_suspended_picks"
                        @click="removeSuspendedPicks"
                        >(REMOVE EXPIRED GAMES)</u
                      >
                    </div>
                    <div
                      class="text-center d-flex justify-content-center align-items-center"
                      style="gap: 10px"
                    >
                      <a
                        style="
                          background-color: var(--darkBackground);
                          border-radius: 5px;
                          width: 100%;
                        "
                        @click="handlePlaceBetClick"
                        class="join-button py-2 form-control"
                        v-bind:class="loading"
                      >
                        <span style="color: var(--white)">Place Bet</span>
                      </a>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/services/api";
import fix from "@/services/fix";
import { pushToDataLayer } from "@/utils/gtm";
import bettingserve from "@/services/bettingserve";
import BetslipModalToggle from "@/components/betslip/BottomModalToggle";
import BetslipSummary from "@/components/betslip/Summary";
import BottomNavigationMenu from "@/components/BottomNavigationMenu";
import Pick from "./Pick";
export default {
  name: "bottom-navigation",
  props: {
    page: {
      required: false,
    },
  },

  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      stake: 50,
      loading: "",
      bestlip_visible: false,
      code: "",
      msg: "",
      share_odds: "",
      copyText: "Copy Link",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: "",
      can_get_bonus: false,
      normalBetsCount: 0,
      total: 0,
      showSuccessModal: false,
      shareMyBet: false,
      btnMinus: "/img/icons/minus.png",
      btnPlus: "/img/icons/plus.png",
    };
  },
  mounted: function () {
    this.fetchNormalBetsCount();
    this.setValue("stake", 50);
    this.initBetslipModal();
    this.initSharebetModal();
    this.previous_odds = this.betslip.odds;
    console.log(this.betslip);

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;
    if (this.$store.state.placeBet == 1) {
      vm.showBetslip();
      this.$store.dispatch("setPlaceBet", 0);
    }
    console.log("VM Store Place Bet:" + this.$store.state.placeBet);
    this.EventBus.$on("event:betslip:show", function () {
      vm.showBetslip();
    });
    this.EventBus.$on("event:betslip:hide", function () {
      vm.hideBetslip();
    });
    this.EventBus.$on("event:betslip:placeBet", function () {
      vm.placeBet(true);
    });
    this.EventBus.$on("profile:balance", function (payload) {
      vm.myProfile = payload;
    });
    this.EventBus.$on("share:bet", function (payload) {
      vm.code = payload.code;
      vm.msg = payload.message;
      vm.share_odds = payload.odds;
      vm.shareBetMessage();
      document.getElementById("open-sharebet").click();
    });
    this.EventBus.$on("odds:changed", function (payload) {
      vm.odds_changed = true;
      vm.updateOdd(
        payload.odd_id,
        payload.odds,
        payload.previous_odds,
        payload.active,
        payload.status
      );
    });
  },
  methods: {
    // setAmount: function (amountToAdd) {
    //   let currentAmount = parseInt(this.stake);

    //   if (isNaN(currentAmount)) {
    //     currentAmount = 0;
    //   }

    //   currentAmount += amountToAdd;

    //   this.stake = currentAmount;
    // },
    setAmount: function (amountToAdd) {
      this.stake = parseFloat(amountToAdd).toFixed(2);
    },
    fetchNormalBetsCount() {
      var vm = this;

      const path = process.env.VUE_APP_MY_BET_COUNT;

      axios
        .get(path, {
          headers: {
            "Content-Type": "application/json",
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          this.normalBetsCount = response.data.total;
          console.log(
            "Success fetching normal bets count:",
            response.data.total
          );
        })
        .catch((err) => {
          console.error("Error fetching normal bets count:", err);
        });
    },
    checkLetters: function () {
      //
    },
    loadBookingCode: function () {
      if (this.booking_code.trim().length > 4) {
        if (this.currentRouteName !== "share") {
          this.$router.push({
            name: "share",
            params: { code: this.booking_code.trim() },
          });
        } else {
          this.EventBus.$emit("share:code", this.booking_code.trim());
        }
      } else {
        this.$toast.open({
          message: "Please enter a valid booking code to proceed",
          type: "error",
          position: "top",
        });
      }
    },
    shareBetMessage: function () {
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * this.stake);
      this.msg = this.msg.replace("{possible_win}", toWin);
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{line}", "");
      this.msg = this.msg.replace("{stake}", this.stake);
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },
    bonusUpdate: function () {
      if (this.can_get_bonus) {
        this.can_get_bonus = false;
      } else {
        this.can_get_bonus = true;
        this.stake = this.myProfile.b2;
      }
    },
    getSportAlt: function (sport_id) {
      switch (parseInt(sport_id)) {
        case 1:
          return "Soccer Icon";
        case 2:
          return "basketball icon";
        case 4:
          return "hockey icon";
        case 5:
          return "tennis icon";
        case 22:
          return "/assets/icons/dart.svg";
        case 20:
          return "/assets/icons/tabletennis.svg";
        case 6:
          return "/assets/icons/handball.svg";
        case 12:
          return "/assets/icons/rugby.svg";
        case 10:
          return "/assets/icons/boxing.svg";
        case 31:
          return "/assets/icons/badminton.svg";
        case 3:
          return "/assets/icons/baseball.svg";
      }
      return "";
    },
    show: function () {
      this.jQuery("#betslip-modal").modal("show");
      this.bestlip_visible = true;
    },
    tax: function () {
      //
    },

    handlePlaceBetClick(event) {
      this.placeBet(event);
    },
    trackPlaceBetSuccessfulButtonClick(event) {
      pushToDataLayer("gtm.betPlacedSuccessful", {
        category: "Button",
        action: "Click",
        label: "betPlacedSuccessful",
        element: event.target,
      });
    },
    trackPlaceBetUnsuccessfulButtonClick(event) {
      pushToDataLayer("gtm.BetPlaceUnsuccessful", {
        category: "Button",
        action: "Click",
        label: "BetPlaceUnsuccessful",
        element: event.target,
      });
    },

    placeBet: function (event) {
      this.reset();
      var p = this.getProfile();
      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.setValue("placeBet", 1);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      var bet_amount = this.getValue("stake");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      if (bet_amount < 10) {
        this.$toast.open({
          message: `Please enter bet amount greater of ${this.fiatCurrency}10 or more`,
          type: "error",
          position: "top",
        });

        return;
      }
      var betslipData = this.betslip;
      if (betslipData.total === 0) {
        this.$toast.open({
          message: "Please Select at least one outcome to continue",
          type: "error",
          position: "top",
        });

        return;
      }
      if (!this.accept_odds_changes && this.odds_changed) {
        this.$toast.open({
          message: "Accept Odds Changes to Proceed",
          type: "error",
          position: "top",
        });

        return;
      }
      var bets = [];
      this.jQuery.each(betslipData.picks, function (k, v) {
        bets.push({
          odd_id: parseInt(v.odd_id),
          producer_id: 3,
        });
      });
      console.log(this.getAuth());
      var data = {
        bet_type: parseInt(1),
        bets: bets,
        booking_code: "",
        campaign: String(utm_campaign),
        ip_address: "",
        medium: String(utm_medium),
        source: this.isMobile() ? 2 : 1,
        stake: parseInt(bet_amount),
        stake_type: this.can_get_bonus ? 2 : 1,
        utm_source: String(utm_source),
        referrer: document.referrer,
      };
      console.log(this.getAuth());
      this.loading = "loading";
      var vm = this;
      var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";
      bettingserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var message = res.data.data;
          vm.bet_id = res.data.bet_id;
          var shareLink = `https://chopLife.com/share/${vm.bet_id}`;
          vm.setSuccess("Success", `${message} Share your bet: ${shareLink}`);
          vm.hideBetslip();
          vm.clearBetSlip();
          vm.trackPlaceBetSuccessfulButtonClick(event);
          // document.getElementById('share-bet-link').textContent = `https://chopLife.com/share/${this.bet_id}`;

          // this.$nextTick(() => {
          //   var collapseShareSlip = document.getElementById('collapseShareSlip');
          //   if (collapseShareSlip) {
          //     collapseShareSlip.classList.add('show');
          //   }
          // });
          vm.removeObject("booking_code");
          vm.removeObject("utm_source");
          this.showSuccessModal = true;
          // setTimeout(() => {
          //   this.showSuccessModal = false;
          // }, 2000);
        })
        .catch((err) => {
          vm.hideBetslip();
          vm.loading = "";
          vm.trackPlaceBetUnsuccessfulButtonClick(event);

          if (err.response) {
            var message = err.response.data.error_message || "Unknown error";

            if (parseInt(err.response.status) === 428) {
              let message = err.response.data.error_message || "Unknown error";
              this.$toast.open({
                message: message,
                type: "error",
                position: "top",
              });

              return;
            }

            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });
              vm.logout();
              return;
            } else if (parseInt(err.response.status) === 422) {
              this.$toast.open({
                message: err.response.data.error_message,
                type: "error",
                position: "top",
              });
            } else {
              console.log("ERRRRRR->");
              message = err.response.data.error_message;
              this.$toast.open({
                message: message,
                type: "error",
                position: "top",
              });
            }

            console.log("Response Error:", JSON.stringify(err.response));
          } else if (err.request) {
            this.$toast.open({
              message: "Please check your network",
              type: "error",
              position: "top",
            });

            console.log("Request Error:", JSON.stringify(err.request));
          } else {
            console.log("Error============:", JSON.stringify(err));
          }
        });
    },
    oddStatus: function () {
      var picks = this.betslip.picks;
      if (picks === undefined || picks.length === 0) {
        return;
      }
      var odds = [];
      this.jQuery.each(picks, function (k, v) {
        odds.push({
          odd_id: parseInt(v.odd_id),
          producer_id: parseInt(v.producer_id),
        });
      });
      var data = {
        odds: odds,
      };
      if (odds.length === 0) {
        return;
      }
      var vm = this;
      var path = process.env.VUE_APP_URL_ODD_STATUS;
      fix
        .post(path, JSON.stringify(data))
        .then((res) => {
          var bets = res.data.message;

          vm.jQuery.each(bets, function (k, v) {
            var oddID = v.id;
            var odds = v.odds;
            var previous_odds = v.previous_odds;
            var status = v.status;
            var active = v.active;
            var producer_status = v.producer_status;
            vm.jQuery.each(picks, function (k1, v1) {
              if (parseInt(v1.odd_id) === parseInt(oddID)) {
                var oddChangeText = "";
                odds = vm.formatOdds(odds);
                if (odds !== vm.formatOdds(v1.odd)) {
                  var direction = odds > v1.odd ? 1 : 0;
                  var directionTxt =
                    parseInt(direction) === 1
                      ? "odds has gone up "
                      : "odds has gone down ";
                  oddChangeText =
                    directionTxt + " from " + v1.odd + " to " + odds;
                }
                v1.status = status;
                v1.active = active;
                v1.odds = odds;
                v1.odd = odds;
                v1.previous_odds = previous_odds;
                v1.producer_status = producer_status;
                v1.odds_change_text = oddChangeText;
                picks[k1] = v1;
              }
            });
          });
          var pk = [];
          vm.jQuery.each(picks, function (k1, v1) {
            pk.push(v1);
          });
          vm.saveObject("bslip", pk);
          vm.autoRefreshUI(vm.$vnode.tag);
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            //
          } else {
            //
          }
        });
    },
    getOddStatus: function (odd) {
      if (odd.active === 0 || odd.producer_status === 0) {
        return '<div class="deactivated-odds">Outcome suspended</div>';
      }
      if (parseInt(odd.status) === 0 || parseInt(odd.status) === -1) {
        return '<div class="deactivated-odds">Outcome Deactivated</div>';
      }
      if (odd.odds_change_text && odd.odds_change_text.length > 0) {
        return (
          '<div class="odds-change" style="">' + odd.odds_change_text + "</div>"
        );
      }
    },
    initSharebetModal: function () {
      var modal = document.getElementById("sharebet-modal");
      var btn = document.getElementById("open-sharebet");
      btn.onclick = function () {};
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#sharebet-modal")) {
          modal.style.display = "none";
        }
      });
    },
    initBetslipModal: function () {
      var vm = this;
      var modal = document.getElementById("betslip-modal");
      var btn = document.getElementById("betslip-init");
      var span = document.getElementById("faso-close");
      btn.onclick = function () {
        modal.style.display = "block";
        vm.previous_odds = vm.betslip.odds;
        vm.oddStatus();
        vm.tax();
      };
      span.onclick = function () {
        modal.style.display = "none";
      };
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
      document.addEventListener("click", (e) => {
        if (e.target === document.querySelector("#betslip-modal")) {
          modal.style.display = "none";
        }
      });
      document.addEventListener("click", (e) => {
        if (e.target === document.querySelector("#betslip-modal")) {
          modal.style.display = "none";
        }
      });
    },
    incrementStake: function () {
      console.log(this.stake);
      if (!this.can_get_bonus) {
        this.stake = this.stake + 10;
      }
    },
    decrementStake: function () {
      if (this.stake - 10 > 9 && !this.can_get_bonus) {
        this.stake = this.stake - 10;
      }
    },
    showBetslip: function () {
      if (document.getElementById("betslip-init") !== null) {
        document.getElementById("betslip-init").click();
        this.$store.dispatch("setPlaceBet", 0);
      }
    },
    hideBetslip: function () {
      document.getElementById("faso-close").click();
    },
    shareBet: function () {
      var p = this.getProfile();
      var betslipData = this.betslip;
      if (betslipData.total === 0) {
        this.$toast.open({
          message: "Please Select atleast one outcome to continue",
          type: "error",
          position: "top",
        });
        return;
      }

      var bets = [];
      this.jQuery.each(betslipData.picks, function (k, v) {
        bets.push({
          market_id: v.market_id,
          match_id: v.match_id,
          outcome_id: v.outcome_id,
          specifier: v.specifier,
        });
      });
      var data = {
        profile_id: p.id,
        bets: bets,
        bet_type: 1,
      };
      this.loading = "loading";
      var vm = this;
      var path = process.env.VUE_APP_URL_SHARE;
      axios
        .post(path, JSON.stringify(data))
        .then((res) => {
          vm.loading = "";
          vm.code = res.data.message.code;
          vm.msg = res.data.error_message;
          vm.share_odds = betslipData.odds;
          vm.shareBetMessage();
          vm.copyText = "Copy";
          vm.copyBookingCode = "Click to Copy";
          document.getElementById("open-sharebet").click();
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              position: "top",
            });
          } else if (err.request) {
            //
          } else {
            //
          }
        });
    },
    copyShareBetLink: function () {
      var link = "https://chopLife.com/share/" + this.bet_id;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.copyText = "Copied";
          // this.showSuccessModal = false;
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    clearAndCloseBetSlip: function () {
      this.hideBetslip();
      this.clearBetSlip();
    },
    copyCode: function () {
      var link = "book#" + this.code + "#stakeAmount";
      this.copyToClipboard(link);
      this.copyBookingCode = "Booking Code Copied";
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    iconSize: function () {
      return 28;
    },
    profile: function () {
      return this.myProfile;
    },
    liveGames: function () {
      return this.$store.state.live_match_counter;
    },
    homePageIcon: function () {
      if (this.page === "home") {
        return this.getURL("/assets/images/home_icon_selected.svg");
      }
      return this.getURL("/assets/images/home_icon.svg");
    },
    homePageFontColor: function () {
      if (this.page === "home") {
        return "yellow-txt";
      }
      return "";
    },
    livePageFontColor: function () {
      if (this.page === "live") {
        return "yellow-txt";
      }
      return "";
    },
    historyPageFontColor: function () {
      if (this.page === "history") {
        return "yellow-txt";
      }
      return "";
    },
    betslip_count: function () {
      if (!this.betslip.total || this.betslip.total == "") {
        return 0;
      }
      return parseInt(this.betslip.total);
    },
    activeBets: function () {
      var p = this.getProfile();
      if (!p) {
        return 0;
      }
      return p.b;
    },
    odds: function () {
      return this.betslip.odds;
    },
    payout: function () {
      return this.betslip.payout;
    },
    betslip: function () {
      return this.$store.state.betslip;
    },
    has_suspended_picks: function () {
      return this.$store.state.has_suspended_picks;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
    modalClass: function () {
      if (parseInt(this.betslip_count) === 0) {
        return "faso-modal-booking-code";
      } else {
        return "faso-modal";
      }
    },
    modalContentClass: function () {
      if (parseInt(this.betslip_count) === 0) {
        return "faso-modal-content-booking-code";
      } else {
        return "faso-modal-content";
      }
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: this.fiatCurrency,
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },
  },
  watch: {
    stake: function (newValue) {
      if (parseInt(newValue) < 1) {
        this.stake = 1;
        newValue = 1;
      }
      this.setValue("stake", newValue);
      this.autoRefreshUI(this.$vnode.tag);
    },
    betslip_count: function (newValue) {
      if (parseInt(newValue) === 0) {
        this.hideBetslip();
      }
    },
  },
  components: {
    Pick,
    BetslipSummary,
    BetslipModalToggle,
    BottomNavigationMenu,
  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-radius: 0 !important;
  background-color: var(--gray-white);
  padding: 0 !important;
  border: none !important;
}

.betslip-modal .modal-dialog {
  box-shadow: 0 -20px 60px rgba(0, 0, 0, 0.2);
}

.betslip-modal .modal-header {
  background: var(--primary-dark);
  color: white;
  border-radius: 0;
}

.betslip-modal .modal-body,
.betslip-modal .faso-modal-body {
  padding: 0;
}

.betslip-modal .modal-header .close {
  color: white;
}

.list-active {
  background-color: #f08b05;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
}

.counter {
  font-size: 10px;
  position: relative;
  top: 5px !important;
  margin-left: 17px;
  color: #0ca678;
}

.major-counter {
  width: 1.6em;
  height: 1.6em;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  margin: 0 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.betslip-modal-header .slip-counter-header {
  background: var(--red);
  color: var(--primary-dark);
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 1.1rem;
}

.betslip-modal-header h5 {
  font-size: 1rem;
}

.betslip-modal-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  background: var(--primary-bg-light);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.betslip-modal-header .modal-title {
  flex-grow: 1;
}

.slip-list-items .slip-list {
  padding: 1rem 13px;
  border-bottom: 1px var(--light-gray) solid;
}

.slip-amount-bg {
  width: 100%;
  padding-bottom: 0.5rem;
}

.slip-amount-bg .depo-btn-wrapper button {
  height: 100%;
  width: 100%;
  background: var(--light-gray);
  color: var(--primary-dark);
  border: none;
  border-radius: 9999px;
}

.slip-amount-bg .prealloc-amts {
  gap: 0.5rem;
}
</style>
